import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"

import SubmenuAbout from "components/menus/submenu-about"



const OurPeoplePage = () => {

  // using static header image, load here
  const data = useStaticQuery(
    graphql`
    query {
      heroImage: file(relativePath: { eq: "backgrounds/tanker-sunset.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
          )
        }
      }
      people: allFile(
          filter: {sourceInstanceName: {eq: "people"}, internal: {mediaType: {eq: "text/markdown"}}}
          sort: {fields: childMarkdownRemark___frontmatter___weight, order: ASC}
        ) {
          edges {
            node {
              id
              name
              childMarkdownRemark {
                frontmatter {
                  education
                  role
                  title
                  weight
                  slug
                  image {
                  childImageSharp {
                    gatsbyImageData(width: 600)
                  }
                }
                }
                html
              }
              relativePath
            }
          }
        }
  
      }
  `
  )


  return (
    <Layout>
      <Seo title="About Us - Our People" />

      {/* Title banner */}
      <ImgBackground image={data.heroImage}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex z-2">
          <TitleBox
            title="Our People"
            caption="About Us"
          >
          </TitleBox>
        </div>
      </ImgBackground>

      {/* content and map */}
      <section className="py-8 md:py-16 lg:py-32">
        <div className="container">

          <div className="lg:flex lg:space-x-12">
            <div className="lg:w-1/4">
              <SubmenuAbout />
            </div>
            <div className="lg:w-3/4">
              <p className="text-2xl mb-20">Our expert global team of advisors with years of combined experience in the marine and clean tech sector are on hand to discuss your project.</p>

              {/* people from markdown content */}
              {data.people.edges.map((item, key) => {
                let person = item.node.childMarkdownRemark
                let profileimage = getImage(person.frontmatter.image)
                return (
                  <div key={key} className="lg:flex lg:space-x-10 mb-20">

                    <div className="w-2/12">

                      {profileimage && (
                        <GatsbyImage
                          image={profileimage}
                          alt={person.frontmatter.title}
                          className="w-full mb-5 rounded"
                        />
                      )}
                    </div>
                    <div className="w-10/12 prose max-w-none">
                      <h2>{person.frontmatter.title}</h2>
                      <p className="text-primary text-xl">{person.frontmatter.role}</p>
                      {person.frontmatter.education && (<p>Education : {person.frontmatter.education}</p>)}
                      <div dangerouslySetInnerHTML={{ __html: person.html }} />

                    </div>

                  </div>
                )
              })}
            </div>
          </div>

        </div>
      </section>


    </Layout>
  )
}

export default OurPeoplePage
