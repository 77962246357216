import * as React from "react"
import SubMenu from "./submenu-links"

const SubmenuAbout = () => {
    
    const menulinks = [
        {
            name:'About Us',
            link: '/about-us/'
        },
        {
            name:'Our People',
            link: '/about-us/our-people/'
        },
        {
            name:'Contact Us',
            link: '/contact-us/'
        }
    ]

    return (
            <SubMenu menu={menulinks} />
    )

}

export default SubmenuAbout
